<template>
  <div>
    <router-view></router-view>

    <footer>
      <div class="container">
        <div class="footer-logo">
          <a href="https://jardincoffee.com/" @click="logoLinkClick('jardin')" target="_blank" class="footer-logo__jardin">
            <img src="@/assets/img/ui/jardin-logo.png"
                 srcset="@/assets/img/ui/jardin-logo@2x.png 2x" alt="">
          </a>

          <a href="https://greenfieldtea.co.uk/en/" @click="logoLinkClick('green')" target="_blank" class="footer-logo__greenfield">
            <img src="@/assets/img/ui/greenfield-logo.png"
                 srcset="@/assets/img/ui/greenfield-logo@2x.png 2x" alt="">
          </a>

          <a href="https://dostavka.magnit.ru/" @click="logoLinkClick('magnit')" target="_blank" class="footer-logo__magnit">
            <img src="@/assets/img/ui/magnit-logo.png"
                 srcset="@/assets/img/ui/magnit-logo@2x.png 2x" alt="">
          </a>
        </div>
      </div>

      <div class="footer-nav">
        <a v-if="mainSettings.rules" v-bind:href="mainSettings.rules" target="_blank" @click="handleRulesButtonClick()">Правила акции</a>
        <a v-if="mainSettings.policy" v-bind:href="mainSettings.policy" target="_blank">политика конфиденциальности</a>
        <a @click="openQuestionPopup" id="triggerQuestionForm">обратная связь</a>
      </div>

      <div class="footer-text">
        С 20.11.2024 по 31.12.2024 приобретайте в магазинах торговой сети «Магнит» или в мобильном приложении «Магнит: акции и доставка» (6+) в разделе «Доставка» (АО «Тандер», г. Краснодар, ул. Леваневского, 185, ОГРН 1022301598549) кофе Jardin или чай Greenfield и регистрируйте чек на сайте greenfield-jardin.ru. Общий срок проведения акции (включая срок выдачи призов): с 20.11.2024 по 20.01.2025. Количество призов ограничено. Информация об организаторе акции, сроках и правилах ее проведения, количестве призов, сроках, месте и порядке вручения призов размещена на сайте greenfield-jardin.ru. Есть ограничения.
      </div>
    </footer>

    <!-- Popup для входа -->
    <div v-if="showLoginPopup" class="popup">
      <div class="popup__overlay" @click="closeLoginPopup"></div>
      <div class="popup__window">
        <button @click="closeLoginPopup" class="popup__close"></button>
        <div class="popup__title">Авторизация</div>
        <div class="popup__content">
          <form @submit.prevent="login" autocomplete="off">
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="login">Логин</label>
                <input type="text" maxlength="18" id="login" v-model="phone_number" name="phone_number" v-mask :class="{ 'is-error': loginFormErrors.phone_number }">
                <span v-if="loginFormErrors.phone_number" class="error-message">{{ loginFormErrors.phone_number }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="password">Пароль</label>
                <input
                  :type="passwordVisible ? 'text' : 'password'"
                  maxlength="255"
                  id="password"
                  v-model="password"
                  name="password"
                />
                <span class="password-toggle" @click="togglePasswordVisibility">
                  <i class="far" :class="passwordVisible ? 'fa-eye' : 'fa-eye-slash'"></i>
                </span>
                <span v-if="loginFormErrors.password" class="error-message">{{ loginFormErrors.password }}<br></span>
                <span class="link" @click="openResetPasswordPopup">Забыли пароль?</span>
              </div>
            </div>
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-main button-main--big button-main--full">
                  <span>Войти</span>
                </button>
              </div>
            </div>
            <div class="field-row field-row-account">
              <div class="field-item">
                Нет аккаунта?
                <button class="button button-main" @click="openRegisterPopup" :disabled="state.promoStatus === 'end'">
                  <span>Регистрация</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Popup для регистрации -->
    <div v-if="showRegisterPopup" class="popup">
      <div class="popup__overlay"></div>
      <div class="popup__window">
        <button @click="closeRegisterPopup" class="popup__close"></button>
        <div class="popup__title">Регистрация</div>
        <div class="popup__content">
          <form @submit.prevent="register" autocomplete="off">
            <!-- Поле Имя -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-name">Имя</label>
                <input type="text" maxlength="255" id="reg-name" v-model="registerForm.first_name" @input="removeSpacesFirstName" name="first_name"
                       autocomplete="off"
                       @blur="clearError('first_name')"
                       :class="{ 'is-error': errors.first_name }">
                <span v-if="errors.first_name" class="error-message">{{ errors.first_name }}</span>
              </div>
            </div>
            <!-- Поле Фамилия -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="last-name">Фамилия</label>
                <input type="text" maxlength="255" id="last-name" v-model="registerForm.last_name" @input="removeSpacesLastName" name="last_name"
                       autocomplete="off"
                       @blur="clearError('last_name')"
                       :class="{ 'is-error': errors.last_name }">
                <span v-if="errors.last_name" class="error-message">{{ errors.last_name }}</span>
              </div>
            </div>
            <!-- Поле Номер телефона -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-phone">Номер телефона</label>
                <input type="text" maxlength="18" id="reg-phone" v-model="registerForm.phone_number" name="phone_number"
                       autocomplete="off"
                       @blur="clearError('phone_number')"
                       :class="{ 'is-error': errors.phone_number }" v-mask>
                <span v-if="errors.phone_number" class="error-message">{{ errors.phone_number }}</span>
              </div>
            </div>
            <!-- Поле Email -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-email">Email</label>
                <input type="text" maxlength="255" id="reg-email" v-model="registerForm.email" name="email"
                       autocomplete="off"
                       @blur="clearError('email')"
                       :class="{ 'is-error': errors.email }">
                <span v-if="errors.email" class="error-message">{{ errors.email }}</span>
              </div>
            </div>
            <!-- Чекбокс согласия с обработкой персональных данных -->
            <div class="field-row field-row--compact" data-cols="1">
              <div class="field-item field-item-checkbox">
                <label for="reg-personality">
                  <input type="checkbox" id="reg-personality" v-model="registerForm.agree_personal_data" @blur="clearError('agree_personal_data')">
                  <span>Согласен(а) <a href="/Согласие_субъекта_на_обработку_персональных_данных.pdf" target="_blank" class="link">на обработку персональных данных</a></span>
                </label>
                <span v-if="errors.agree_personal_data" class="error-message">{{ errors.agree_personal_data }}</span>
              </div>
            </div>
            <!-- Чекбокс согласия с правилами акции -->
            <div class="field-row field-row--compact" data-cols="1">
              <div class="field-item field-item-checkbox">
                <label for="reg-rules">
                  <input type="checkbox" id="reg-rules" v-model="registerForm.agree_rules" @blur="clearError('agree_rules')">
                  <span>Согласен(на) <a class="link" v-if="mainSettings.rules" v-bind:href="mainSettings.rules" target="_blank" @click="handleRulesButtonClick()">с правилами акции</a></span>
                </label>
                <span v-if="errors.agree_rules" class="error-message">{{ errors.agree_rules }}</span>
              </div>
            </div>
            <!-- Чекбокс согласия с правилами акции -->
            <div class="field-row field-row--compact" data-cols="1">
              <div class="field-item field-item-checkbox">
                <label for="reg-partners">
                  <input type="checkbox" id="reg-partners" v-model="registerForm.agree_to_advertising">
                  <span>Я согласен(на) на получение персональных предложений от партнера</span>
                </label>
              </div>
            </div>
            <!-- Кнопка для отправки формы регистрации -->
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-main button-main--big button-main--full">
                  <span>Регистрация</span>
                </button>
              </div>
            </div>
            <!-- Кнопка для переключения на окно входа -->
            <div class="field-row field-row-account">
              <div class="field-item">
                Уже есть аккаунт?
                <button class="button button-main" @click="switchToLogin">
                  <span>Войти</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showRegisterPopupSuccess" class="popup">
      <div class="popup__overlay" @click="closeRegisterPopupSuccess"></div>
      <div class="popup__window">
        <button @click="closeRegisterPopupSuccess" class="popup__close"></button>
        <div class="popup__content">
          <div class="success-message">
            Спасибо за регистрацию.
            <br>Пароль для входа отправлен на&nbsp;email.
            <br>Проверь, пожалуйста, папку спам.
          </div>
        </div>
      </div>
    </div>

    <!-- Popup успешной отправки вопроса -->
    <div v-if="showQuestionPopup" class="popup">
      <div class="popup__overlay" @click="closeQuestionPopup"></div>
      <div class="popup__window">
        <button @click="closeQuestionPopup" class="popup__close"></button>
        <div class="popup__title">Обратная связь</div>
        <div class="popup__content">
          <form @submit.prevent="submitQuestion" autocomplete="off">
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="feedback-name">Имя</label>
                <input
                    type="text"
                    v-model="questionForm.name"
                    id="feedback-name"
                    name="name"
                    @input="removeSpacesName"
                    :disabled="isNameFieldDisabled" />
                <span v-if="feedbackErrors.name" class="error-message">{{ feedbackErrors.name }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="feedback-email">Email</label>
                <input
                    type="text"
                    v-model="questionForm.email"
                    id="feedback-email"
                    name="email"
                    :disabled="user && !!user.email" />
                <span v-if="feedbackErrors.email" class="error-message">{{ feedbackErrors.email }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="feedback-subject">Тема обращения</label>
                <select name="subject" id="feedback-subject" v-model="questionForm.subject">
                  <option value="" disabled selected>Выберите тему</option>
                  <option v-for="option in options" :key="option.id" :value="option.id">
                    {{ option.title }}
                  </option>
                </select>
                <span v-if="feedbackErrors.subject" class="error-message">{{ feedbackErrors.subject }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="feedback-message">Сообщение</label>
                <textarea v-model="questionForm.text" id="feedback-message" name="text"></textarea>
                <span v-if="feedbackErrors.text" class="error-message">{{ feedbackErrors.text }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="files" class="attach-file">Прикрепить файл</label>
                <input
                    type="file"
                    id="files"
                    name="files"
                    multiple
                    @change="handleFileChange"
                    accept=".jpg, .jpeg, .png, .gif, .tiff, .webp, .pdf">
                <div class="attach-info">
                  <p>Разрешенные форматы файлов: .jpg, .jpeg, .png, .gif, .tiff, .webp, .pdf.
                    <br>Максимальный размер файла: 500 MB.
                    <br>Максимальное количество файлов: 10.</p>
                </div>


                <!-- Отображение превью файлов -->
                <div class="file-preview" v-if="filePreviews.length">
                  <div v-for="(file, index) in filePreviews" :key="index" class="file-preview-item">
                    <div v-if="file.type.startsWith('image')" class="file-preview-item__img">
                      <img :src="file.preview" :alt="file.name" />
                    </div>
                    <button @click="removeFile(index)" class="remove-file-btn"></button>
                  </div>
                </div>
              </div>
            </div>

            <div class="field-row" data-cols="1">
              <div class="field-item field-item-checkbox">
                <label for="feedback-personality">
                  <input type="checkbox" id="feedback-personality" v-model="questionForm.agree_personal_data">
                  <span>Согласен(а) <a href="/Согласие_субъекта_на_обработку_персональных_данных.pdf" target="_blank" class="link">на обработку персональных данных</a></span>
                </label>
                <span v-if="feedbackErrors.agree_personal_data" class="error-message">{{ feedbackErrors.agree_personal_data }}</span>
              </div>
            </div>
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-main button-main--full">Отправить</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showQuestionPopupSuccess" class="popup">
      <div class="popup__overlay" @click="closeQuestionPopupSuccess"></div>
      <div class="popup__window">
        <button @click="closeQuestionPopupSuccess" class="popup__close"></button>
        <div class="popup__content">
          <div class="success-message">
            Спасибо за обращение!
            <br>Ваш вопрос будет обработан в течение 72 часов.
          </div>
        </div>
      </div>
    </div>

    <!-- Popup для восстановления пароля -->
    <div v-if="showResetPasswordPopup" class="popup">
      <div class="popup__overlay" @click="closeResetPasswordPopup"></div>
      <div class="popup__window">
        <button @click="closeResetPasswordPopup" class="popup__close"></button>
        <div class="popup__title">Восстановление пароля</div>
        <div class="popup__content">
          <form @submit.prevent="submitResetPassword" autocomplete="off">
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-phone">Номер телефона</label>
                <input type="text" maxlength="18" id="reg-phone" v-model="resetPasswordForm.phone_number" name="phone_number"
                       :class="{ 'is-error': resetPasswordErrors.phone_number }" v-mask>
                <span v-if="resetPasswordErrors.phone_number" class="error-message">{{ resetPasswordErrors.phone_number }}</span>
              </div>
            </div>
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-main button-main--big button-main--full">
                  <span>Отправить</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showResetPasswordSuccess" class="popup">
      <div class="popup__overlay" @click="closeResetPasswordPopupSuccess"></div>
      <div class="popup__window">
        <button @click="closeResetPasswordPopupSuccess" class="popup__close"></button>
        <div class="popup__title">
          Восстановление пароля
        </div>
        <div class="popup__content">
          <div class="success-message">
            Новый пароль был отправлен на номер телефона {{ formattedPhoneNumber }}
          </div>
          <p>
            <button class="button button-main button-main--big button-main--full" @click="switchToLogin">
              <span>Войти</span>
            </button>
          </p>
        </div>
      </div>
    </div>

    <!-- Popup статусов акции -->
    <div v-if="showPromoNotStarted" class="popup">
      <div class="popup__overlay" @click="closeShowPromoNotStarted"></div>
      <div class="popup__window">
        <button @click="closeShowPromoNotStarted" class="popup__close"></button>
        <div class="popup__content">
          Акция ещё не началась
        </div>
      </div>
    </div>
    <div v-if="showPromoEnd" class="popup">
      <div class="popup__overlay" @click="closeShowPromoEnd"></div>
      <div class="popup__window">
        <button @click="closeShowPromoEnd" class="popup__close"></button>
        <div class="popup__title" style="margin-bottom: 1rem;">
          Акция завершена.
          <br>Спасибо за участие!
        </div>
        <div class="popup__content">
          Вручение призов осуществляется до&nbsp;20&nbsp;января&nbsp;2025&nbsp;г.
        </div>
      </div>
    </div>

    <div style="position: absolute; bottom: 100%; overflow: hidden;">
      <div id="apm-widget"></div>
    </div>
  </div>
</template>

<script>
/* global ym */
/* global qrWidget */
import { state } from "@/store";
import {
  fetchFAQ,
  getToken,
  getUserInfo,
  removeToken,
  authorize,
  register,
  getMainSettings,
  sendFeedback,
  setAuthHeader,
  sendResetPassword,
  fetchTopics,
  getUserChecks
} from './api';
import metrikaMixin from "@/mixins/metrikaMixin";

export default {
  name: 'App',
  mixins: [metrikaMixin],
  setup() {
    return {
      state,
    };
  },
  data() {
    return {
      scrollPercentages: [25, 50, 75],
      hasReached: {
        scroll_to_footer: false,
        scroll_page_25: false,
        scroll_page_50: false,
        scroll_page_75: false,
        timer_15_seconds: false,
        timer_30_seconds: false,
      },
      timerStarted: false,
      checkInterval: null,
      checksTotal: 0,
      checkSoon: false,
      utmParams: {},
      clientId: null,
      isHeaderScrolled: false,
      scrollThreshold: 0,
      rootUrl: window.location.origin,
      userId: '',
      userUuid: '',
      isMobileNavOpened: false,
      faq: [],
      prizes: [],
      user: null,
      showLoginPopup: false,
      showRegisterPopup: false,
      showRegisterPopupSuccess: false,
      showQuestionPopup: false,
      showQuestionPopupSuccess: false,
      showResetPasswordPopup: false,
      showResetPasswordSuccess: false,
      showPromoNotStarted: false,
      showPromoEnd: false,
      AccountPopup: false,
      questionForm: {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false,
        subject: '',
        files: []
      },
      options: [],
      filePreviews: [],
      phone_number: '',
      password: '',
      passwordVisible: false,
      registerForm: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        agree_personal_data: false,
        agree_rules: false,
        agree_to_advertising: false
      },
      loginForm: {
        phone_number: '',
        password: '',
      },
      loginFormErrors: {},
      resetPasswordForm: {
        phone_number: '',
      },
      checks: {
        count: 0,
        next: null,
        previous: null,
        results: [],
      },
      checkOffset: 0,
      checkLimit: 18,
      feedbackErrors: {},
      resetPasswordErrors: {},
      errors: {},
      responseError: '',
      days: 0,
      hours: 0,
      minutes: 0,
      timerMessage: '',
      mainSettings: {},
      sections: [
        { name: 'Покупайте товары', component: 'PurchaseSection', scrolled: false },
        { name: 'Регистрируйте чеки', component: 'RegisterSection', scrolled: false},
        { name: 'Участвуйте в викторине', component: 'QuizSection', scrolled: false },
        { name: 'Выигрывайте призы', component: 'PrizeSection', scrolled: false }
      ],
      activeSection: 0,
      scrolledSection: -1

    };
  },
  async created() {
    try {
      this.mainSettings = await getMainSettings();
    } catch (error) {
      console.error('Failed to fetch main settings', error);
    }
    this.loadFAQ();
    this.checkAuthentication();
    this.fetchTimerData();
  },
  mounted() {
    const promoShown = sessionStorage.getItem('promoShown');
    if (!promoShown) {
      this.showPromoEnd = true; // Показываем попап
      sessionStorage.setItem('promoShown', 'true'); // Помечаем, что попап был показан
    }
    this.getClientId();
    this.getUTMParams();
    // this.fetchUsersCheck();
    document.addEventListener('click', this.handleDynamicButtonClick);
    if (!this.timerStarted) {
      this.startTimer(15, 'timer_15_seconds');
      this.startTimer30(30, 'timer_30_seconds');
      this.timerStarted = true;
    }
    this.fetchOptions();
    document.documentElement.style.setProperty('--text-accent-1-1', 'var(--desired-color)');
    window.addEventListener('colorChange', (event) => {
      this.color = event.detail;
    });
    setAuthHeader();
    const token = getToken();
    if (token) {
      getUserInfo().then(userInfo => {
        if (userInfo) {
          this.userId = userInfo.id;
          this.userUuid = userInfo.uuid;
          this.user = {
            first_name: userInfo.first_name,
            last_name_initial: userInfo.last_name.charAt(0),
          };
          this.questionForm.name = userInfo.first_name;
          this.questionForm.email = userInfo.email;
          this.initQrWidget();
        }
      }).catch(error => {
        console.error('Failed to fetch user info on mount', error);
      });
    }
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    formattedPhoneNumber() {
      // Убираем знак '+' и все нецифровые символы
      const cleaned = this.phoneNumber.replace(/\D/g, '');

      // Проверяем, что номер начинается с '7' и содержит 11 цифр
      if (cleaned.length === 11 && cleaned.startsWith('7')) {
        return `+7 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7, 9)}-${cleaned.slice(9, 11)}`;
      }

      return ' ';
    }
  },
  methods: {
    getClientId() {
      if (typeof ym !== 'undefined') {
        ym(98788876, 'getClientID', (clientId) => {
          this.clientId = clientId;
          this.getUTMParams();
        });
      } else {
        console.error('Yandex.Metrica не доступна');
      }
    },
    executeAdriverEvent(page) {
      const sidMapping = {
        faq: 'click_faq',
        prizes: 'click_prizes',
        winners: 'click_winners',
        register_user_closed: 'abandoned_user_registration_close_form',
        check_upload_closed: 'abandoned_check_registration_close_form',
        click_greenfield: 'click_greenfield',
        click_jardin: 'click_jardin',
        click_magnit: 'click_magnit',
        click_home: 'click_main',
        upload_check_open: 'click_register_check',
        click_how_use: 'click_how_participate',
        click_account: 'click_lk',
        register_user_success: 'successful_user_registration',
        check_upload_success: 'successful_check_registration',
        timer_15: 'timer_15'
      };

      if (!sidMapping[page]) return;

      const sid = 229236; // ваш статический sid
      const bt = 62; // ваш статический bt
      const sz = sidMapping[page];

      // Вызов Adriver-кода
      (function (e, n) {
        function o(e, n, o) {
          n = n || "&";
          o = o || "=";
          var d = [];
          for (var r in e)
            if (Object.prototype.hasOwnProperty.call(e, r)) {
              d.push(r + o + encodeURIComponent(e[r]));
            }
          return d.join(n);
        }
        function d(e, n) {
          var o = e.cookie.match("(^|;) ?" + n + "=([^;]*)(;|$)");
          return o ? decodeURIComponent(o[2]) : null;
        }
        var r,
            t,
            i,
            c,
            u;
        (r = e),
            (t = n),
            (i = document.domain),
            (c = { tail256: document.referrer || "unknown" }),
        void 0 !==
        (u = (function (e) {
          var n = {};
          if (e) {
            var o = e.split("&");
            for (var d in o)
              if (Object.prototype.hasOwnProperty.call(o, d)) {
                var r = o[d].split("=");
                void 0 !== r[0] &&
                void 0 !== r[1] &&
                (n[r[0]] = decodeURIComponent(r[1]));
              }
          }
          return n;
        })(window.location.search.substring(1))).adrclid && (r.fsid = u.adrclid),
        null !== d(document, "adrcid") && (r.cid = d(document, "adrcid")),
        t &&
        t.id &&
        null !== d(document, t.id) &&
        (r.suid = i + "_" + encodeURIComponent(d(document, t.id))),
            t && t.gid1
                ? (r.gid1 = t.gid1)
                : null !== d(document, "_ga") &&
                (r.gid1 = encodeURIComponent(d(document, "_ga"))),
            t && t.yid1
                ? (r.yid1 = t.yid1)
                : null !== d(document, "_ym_uid") &&
                (r.yid1 = encodeURIComponent(d(document, "_ym_uid"))),
            (r.loc = encodeURIComponent(window.location.href)),
        r.custom && (r.custom = o(r.custom, ";")),
            (function (e, n) {
              !(function (e) {
                if (
                    ((e = e.split("![rnd]").join(~~(1e6 * Math.random()))),
                    document.createElement && document.body)
                ) {
                  var n = document.createElement("img");
                  (n.style.position = "absolute"),
                      (n.style.display = "none"),
                      (n.style.width = n.style.height = "0px"),
                      n.setAttribute("referrerpolicy", "no-referrer-when-downgrade"),
                      (n.src = e),
                      document.body.appendChild(n);
                } else {
                  var o = new Image();
                  o.setAttribute("referrerpolicy", "no-referrer-when-downgrade"),
                      (o.src = e);
                }
              })(
                  "https://ad.adriver.ru/cgi-bin/rle.cgi?" +
                  e +
                  "&rnd=![rnd]" +
                  (n ? "&" + n : "")
              );
            })(o(r), o(c));
      })({ sid: sid, bt: bt, sz: sz }, { id: "", gid1: "", yid1: "" });
    },
    executeDSPCounterEvent(siteArea) {
      const sid = 229303; // ваш статический sid
      const siteAreaMapping = {
        '30_sec': 'TJCuegy2',
        'login_success': 'qh1jImpT',
        'check_upload_success': 'hAf9oUO0',
        'buttons_click': 'bG46a4ie',
        'faq_click': 'USZY4vWn',
      };

      const siteAreaId = siteAreaMapping[siteArea] || 'USZY4vWn'; // если не нашли, использовать дефолтный
      // Проверка и отложенный вызов если DSPCounter еще не загружен
      const checkDSPCounter = setInterval(() => {
        if (window.DSPCounter) {
          clearInterval(checkDSPCounter);
          window.DSPCounter('send', {
            'sid': sid,
            'site_area': siteAreaId,
            'user_id': '',
            'lead_id': '',
            'order_sum': ''
          });
        }
      }, 100); // проверяем каждые 100ms
    },
    handleDynamicButtonClick(event) {
      if (event.target.id === 'apm-close-widget-button') {
        this.$sendMetrik('reachGoal', 'check_upload_closed');
        this.executeAdriverEvent('check_upload_closed');
        // Логика обработки клика
      }
    },
    startTimer(seconds, eventKey) {
      setTimeout(() => {
        if (!this.hasReached[eventKey]) {
          this.$sendMetrik('reachGoal', eventKey);
          this.hasReached[eventKey] = true;
          this.executeAdriverEvent('timer_15');
        }
      }, seconds * 1000);
    },
    startTimer30(seconds, eventKey) {
      setTimeout(() => {
        if (!this.hasReached[eventKey]) {
          this.hasReached[eventKey] = true;
          this.executeDSPCounterEvent('30_sec');
        }
      }, seconds * 1000);
    },
    startCheckingForNewChecks() {
      // Если уже идет проверка, не запускаем ещё раз
      if (this.checkInterval) return;

      this.checkInterval = setInterval(async () => {
        try {
          const checksData = await getUserChecks(this.checkOffset, this.checkLimit);
          if (checksData.count && checksData.count !== state.checksTotal) {
            // Обновляем данные, если количество чеков изменилось
            state.checksTotal = checksData.count;
            state.checks.results = checksData.results;

            // Останавливаем таймер
            this.stopCheckingForNewChecks();

            // Очищаем флаг ожидания
            state.checkSoon = false;
          }
        } catch (error) {
          console.error('Error checking for new checks:', error);
        }
      }, 5000); // Проверяем каждые 5 секунд
    },
    stopCheckingForNewChecks() {
      if (this.checkInterval) {
        clearInterval(this.checkInterval);
        this.checkInterval = null;
      }
    },
    getUTMParams() {
      const params = new URLSearchParams(window.location.search);
      const utmParams = {
        utm_source: params.get('utm_source'),
        utm_medium: params.get('utm_medium'),
        utm_campaign: params.get('utm_campaign'),
        utm_term: params.get('utm_term'),
        utm_content: params.get('utm_content'),
        click_id: params.get('click_id')
      };

      // Фильтрация null значений
      this.utmParams = Object.fromEntries(
          Object.entries(utmParams).filter(([, value]) => value !== null)
      );

      // Добавляем clientId в utmParams после его получения
      if (this.clientId) {
        this.utmParams.client_id = this.clientId;
      }
    },
    async fetchOptions() {
      try {
        this.options = await fetchTopics(10, 0);
      } catch (error) {
        console.error("Ошибка загрузки тем:", error);
      }
    },
    handleFileChange(event) {
      const files = Array.from(event.target.files);

      // Фильтрация по разрешённым типам файлов и размеру
      const validFiles = files.filter(file => {
        const isValidType = /\.(jpg|jpeg|png|gif|tiff|webp|pdf)$/i.test(file.name);
        const isValidSize = file.size <= 500 * 1024 * 1024; // 500 MB
        return isValidType && isValidSize;
      });

      // Ограничение на 10 файлов
      if (validFiles.length + this.filePreviews.length > 10) {
        alert("Нельзя прикрепить больше 10 файлов.");
        return;
      }

      // Добавляем файлы в состояние и показываем их превью
      validFiles.forEach(file => {
        const preview = {
          file,
          name: file.name,
          type: file.type,
          preview: URL.createObjectURL(file) // для изображений
        };
        this.filePreviews.push(preview);
        this.questionForm.files.push(file);
      });
    },

    // Удаление файла
    removeFile(index) {
      // Удаляем файл из массива файлов
      this.filePreviews.splice(index, 1);
      this.questionForm.files.splice(index, 1);
    },
    handleRulesButtonClick() {
      // this.$sendMetrik('reachGoal', 'Rules_Click');
    },
    addBodyClass() {
      document.body.classList.add('is-popup-opened');
    },
    removeBodyClass() {
      document.body.classList.remove('is-popup-opened');
    },
    async logout() {
      state.checkSoon = false;
      state.promoStatus = '';
      state.checksTotal = 0;
      state.checks = {
        count: 0,
        next: null,
        previous: null,
        results: [],
      };
      state.user = {
        first_name: null,
        last_name: null,
        phone_number: null,
        email: null
      };
      const widget = document.getElementById('apm-widget');
      if (widget) {
        widget.innerHTML = ''; // Очистка содержимого виджета
      }

      this.toggleMobileNav(); // Логика для переключения навигации
      removeToken(); // Удаление токена (например, из localStorage)
      this.user = null; // Обновление состояния пользователя
      this.questionForm = { // Очистка формы
        name: '',
        email: '',
        text: '',
        agree_personal_data: false
      };

      // Ожидание завершения обновления DOM
      await this.$nextTick();

      // Выполняем перенаправление после обновления DOM
      window.location.href = '/';
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    formatText(text) {
      // Замена переносов строк на <br>
      let formattedText = text.replace(/\r?\n/g, '<br>');

      // Регулярное выражение для поиска URL
      const urlPattern = /(https?:\/\/[^\s]+)/g;

      // Замена URL на HTML-ссылки
      formattedText = formattedText.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');

      return formattedText;
    },
    clearError(field) {
      this.errors = { ...this.errors, [field]: null };
    },
    redirectToPrivatePage() {
      this.$sendMetrik('reachGoal', 'click_account');
      this.executeAdriverEvent('click_account');
      this.$router.push('/private');
      this.isMobileNavOpened = false;
    },
    redirectToHomePage(page) {
      if (page == 'link') {
        this.$sendMetrik('reachGoal', 'click_home');
        this.executeAdriverEvent('click_home');
      }
      this.$router.push('/');
    },
    logoLinkClick(page) {
      if (page == 'magnit') {
        this.$sendMetrik('reachGoal', 'click_magnit');
      } else if (page == 'jardin') {
        this.$sendMetrik('reachGoal', 'click_jardin');
        this.executeAdriverEvent('click_jardin');
      } else if (page == 'green') {
        this.$sendMetrik('reachGoal', 'click_greenfield');
        this.executeAdriverEvent('click_greenfield');
      }
    },
    removeSpacesFirstName() {
      const input = event.target.value.trimStart();
      this.registerForm.first_name = input.length > 0 ? input : '';
    },
    removeSpacesLastName() {
      const input = event.target.value.trimStart();
      this.registerForm.last_name = input.length > 0 ? input : '';
    },
    removeSpacesName() {
      const input = event.target.value.trimStart();
      this.questionForm.name = input.length > 0 ? input : '';
    },
    initQrWidget() {
      if (!document.getElementById('apm-scan-qr')) {
        qrWidget.init('apm-widget', {
          api: 'https://api.apmcheck.ru',
          apiKey: '3f9cbff2-2d9f-41b5-ab14-bf89252ac7d1',
          userUuid: this.userUuid,
          callbacks: {
            onReceiptSentSuccess: () => {
              this.$sendMetrik('reachGoal', 'check_upload_success');
              this.executeDSPCounterEvent('check_upload_success');
              this.executeAdriverEvent('check_upload_success');
              state.checkSoon = true;
              this.startCheckingForNewChecks();
            }
          }
        });
      }
    },
    toggleMobileNav() {
      this.isMobileNavOpened = !this.isMobileNavOpened;
    },
    openAccountPopup() {
      this.addBodyClass();
      this.AccountPopup = true;
    },
    closeAccountPopup() {
      this.removeBodyClass();
      this.AccountPopup = false;
    },
    openQuestionPopup() {
      this.executeDSPCounterEvent('buttons_click');
      this.addBodyClass();
      this.showQuestionPopup = true;
      this.questionForm.name = state.user.first_name;
      this.questionForm.email = state.user.email;
    },
    closeQuestionPopup() {
      this.removeBodyClass();
      this.showQuestionPopup = false;
      this.resetForm();
      // Очищаем файлы и их превью
      this.filePreviews = [];
      this.questionForm.files = [];
    },
    openQuestionPopupSuccess() {
      this.addBodyClass();
      this.showQuestionPopupSuccess = true;
    },
    closeQuestionPopupSuccess() {
      this.removeBodyClass();
      this.showQuestionPopupSuccess = false;
      this.resetForm();
    },
    openResetPasswordPopup() {
      this.showResetPasswordPopup = true;
      this.closeLoginPopup();
      this.addBodyClass();
    },
    closeResetPasswordPopup() {
      this.removeBodyClass();
      this.showResetPasswordPopup = false;
      this.resetForm();
    },
    openResetPasswordPopupSuccess() {
      this.addBodyClass();
      this.showResetPasswordSuccess = true;
    },
    closeResetPasswordPopupSuccess() {
      this.removeBodyClass();
      this.showResetPasswordSuccess = false;
      this.resetForm();
    },
    closeShowPromoNotStarted() {
      this.removeBodyClass();
      this.showPromoNotStarted = false;
    },
    closeShowPromoEnd() {
      this.removeBodyClass();
      this.showPromoEnd = false;
    },
    async submitQuestion() {
      try {
        this.feedbackErrors = {};

        // Валидация формы
        if (!this.questionForm.name) {
          this.feedbackErrors.name = 'Введите имя';
        }
        if (!this.questionForm.email) {
          this.feedbackErrors.email = 'Введите email';
        }
        if (!this.questionForm.text) {
          this.feedbackErrors.text = 'Введите сообщение';
        }
        if (!this.questionForm.agree_personal_data) {
          this.feedbackErrors.agree_personal_data = 'Необходимо согласиться на обработку персональных данных';
        }
        if (!this.questionForm.subject || this.questionForm.subject === 'Выберите тему') {
          this.feedbackErrors.subject = 'Выберите тему';
        }

        if (Object.keys(this.feedbackErrors).length > 0) {
          return;
        }

        // Отправка данных формы через API
        await sendFeedback({
          email: this.questionForm.email,
          name: this.questionForm.name,
          text: this.questionForm.text,
          subject: this.questionForm.subject,
          files: this.questionForm.files // отправляем файлы
        });

        this.closeQuestionPopup();
        this.openQuestionPopupSuccess();
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    async submitResetPassword() {
      try {
        const { phone_number } = this.resetPasswordForm;
        this.resetPasswordErrors = {};
        const formattedPhoneNumber = `+${phone_number.replace(/\D/g, '')}`;

        // Валидация длины телефонного номера
        if (formattedPhoneNumber.length < 12) {
          this.resetPasswordErrors.phone_number = 'Введите корректный телефон';
          return; // Выходим, если есть ошибка валидации
        }

        // Отправка данных формы через API
        await sendResetPassword({ phone_number: formattedPhoneNumber });

        // Успешная отправка, здесь можно закрыть попап
        this.phoneNumber = formattedPhoneNumber;
        this.closeResetPasswordPopup();
        this.openResetPasswordPopupSuccess();
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    resetForm() {
      this.questionForm = {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        subject: '',
        agree_personal_data: false,
        files: false
      };

      this.phone_number = '';
      this.password = '';

      this.registerForm = {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        agree_personal_data: false,
        agree_rules: false,
        agree_to_advertising: false
      };

      this.resetPasswordForm = {
        phone_number: '',
      };

      this.feedbackErrors = {};
    },
    toggleAccordion(event) {
      event.target.classList.toggle('is-opened');
      const textBlock = event.target.nextElementSibling;
      textBlock.classList.toggle('is-opened');
    },
    handleScroll() {
      const scrollPosition = window.scrollY + window.innerHeight;
      const pageHeight = document.documentElement.scrollHeight;

      // Если футер в зоне видимости
      const footer = document.querySelector('footer');
      if (footer) {
        const footerVisible = footer.getBoundingClientRect().top < window.innerHeight;
        if (footerVisible && !this.hasReached.scroll_to_footer) {
          this.$sendMetrik('reachGoal', 'scroll_to_footer');
          this.hasReached.scroll_to_footer = true;
        }
      }

      // Отслеживание процентов прокрутки
      const scrollPercentage = (scrollPosition / pageHeight) * 100;
      this.scrollPercentages.forEach((percentage) => {
        const eventKey = `scroll_page_${percentage}`;
        if (scrollPercentage >= percentage && !this.hasReached[eventKey]) {
          this.$sendMetrik('reachGoal', eventKey);
          this.hasReached[eventKey] = true;
        }
      });


      this.isHeaderScrolled = window.scrollY > this.scrollThreshold;

      const sections = document.querySelectorAll('.register-section');

      let currentSectionIndex = -1;

      sections.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        if (rect.top - 400 <= 0 && rect.bottom > 0) {
          currentSectionIndex = index;
        }
      });

      if (currentSectionIndex !== -1) {
        this.activeSection = currentSectionIndex;
        // Добавляем класс 'is-scrolled' ко всем предыдущим секциям
        for (let i = 0; i < currentSectionIndex; i++) {
          this.sections[i].scrolled = true;
        }
        // Убираем класс 'is-scrolled' со всех следующих секций
        for (let i = currentSectionIndex; i < this.sections.length; i++) {
          this.sections[i].scrolled = false;
        }
      } else {
        this.activeSection = 0;
        this.sections.forEach(section => section.scrolled = false);
      }
    },
    scrollToSection(index) {
      const section = this.$refs['section-' + index][0];
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth'
      });
      this.activeSectionIndex = index;
    },
    isActive(index) {
      return this.activeSectionIndex === index;
    },
    isScrolled(index) {
      return index < this.activeSectionIndex;
    },
    async checkAuthentication() {
      const token = getToken();
      if (token) {
        try {
          const userInfo = await getUserInfo();
          this.user = userInfo;
          this.questionForm.name = userInfo.first_name;
          this.questionForm.email = userInfo.email;
        } catch (error) {
          if (error.response && error.response.data) {
            this.responseError = error.response.data.detail;
          }
        }
      }
    },
    async fetchTimerData() {
      try {
        const { start_date, end_date } = await getMainSettings();
        const now = new Date();
        const startDate = new Date(start_date);
        const endDate = new Date(end_date);
        endDate.setDate(endDate.getDate() + 1); // Увеличиваем дату окончания на 1 день
        endDate.setHours(0, 0, 0, 0); // Устанавливаем время окончания на 00:00 следующего дня

        if (now < startDate) {
          // Если акция еще не началась
          // alert('Не началась')
          this.calculateTimeDifference(now, startDate);
          this.timerMessage = 'до начала акции';
          this.promoDates = start_date + ' - ' + end_date;
          state.promoStatus = 'not-started'
        } else if (now >= startDate && now < endDate) {
          // Если акция идет в данный момент
          // alert('Идёт')
          this.calculateTimeDifference(now, endDate);
          this.timerMessage = 'До окончания приема чеков';
          this.promoDates = start_date + ' - ' + end_date;
          state.promoStatus = 'start'
        } else {
          // alert('Закончилась')
          // Если Акция завершена
          this.days = 0;
          this.hours = 0;
          this.minutes = 0;
          this.timerMessage = 'Акция завершилась';
          this.promoDates = startDate + ' - ' + end_date;
          state.promoStatus = 'end'
        }
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    calculateTimeDifference(start, end) {
      const diff = end - start;

      const totalSeconds = Math.floor(diff / 1000);
      this.days = Math.floor(totalSeconds / (3600 * 24));
      this.hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
      this.minutes = Math.floor((totalSeconds % 3600) / 60);
    },
    openLoginPopup() {
      if (state.promoStatus == 'start' || state.promoStatus == 'end') {
        this.addBodyClass();
        this.isMobileNavOpened = false;
        this.showLoginPopup = true;
      } else if (state.promoStatus == 'not-started') {
        this.addBodyClass();
        this.isMobileNavOpened = false;
        this.showPromoNotStarted = true;
      }
    },
    closeLoginPopup() {
      this.removeBodyClass();
      this.showLoginPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    openRegisterPopup() {
      this.showRegisterPopup = true;
      this.closeLoginPopup();
      this.responseError = '';
      this.addBodyClass();
    },
    closeRegisterPopup() {
      this.$sendMetrik('reachGoal', 'register_user_closed');
      this.executeAdriverEvent('register_user_closed');
      this.addBodyClass();
      this.showRegisterPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    closeRegisterFinishPopup() {
      this.addBodyClass();
      this.showRegisterPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    openRegisterPopupSuccess() {
      this.executeDSPCounterEvent('login_success');
      this.$sendMetrik('reachGoal', 'register_user_success');
      this.executeAdriverEvent('register_user_success');
      this.addBodyClass();
      this.showRegisterPopupSuccess = true;
      this.responseError = '';
    },
    closeRegisterPopupSuccess() {
      this.removeBodyClass();
      this.showRegisterPopupSuccess = false;
      this.responseError = '';
      this.resetForm();
    },
    async loadFAQ() {
      try {
        const data = await fetchFAQ();
        this.faq = data.results.map(item => ({
          question: item.question,
          answer: item.answer
        }));
      } catch (error) {
        console.error('Error fetching winners:', error);
      }
    },
    async login() {
      try {
        const formattedPhoneNumber = `+${this.phone_number.replace(/\D/g,'')}`;
        this.loginFormErrors = {};
        if (formattedPhoneNumber.length < 12) {
          this.loginFormErrors.phone_number = 'Введите корректный телефон';
        }
        if (this.password.length < 2) {
          this.loginFormErrors.password = 'Введите пароль';
        }
        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.loginFormErrors).length > 0) {
          return;
        }

        await authorize(formattedPhoneNumber, this.password);
        setAuthHeader();
        setTimeout(async () => {
          try {
            const userInfo = await getUserInfo();
            if (userInfo) {
              this.userId = userInfo.id;
              this.userUuid = userInfo.uuid;
              this.user = {
                first_name: userInfo.first_name,
                last_name_initial: userInfo.last_name.charAt(0),
              };
              this.questionForm.name = userInfo.first_name;
              this.questionForm.email = userInfo.email;
              this.closeLoginPopup();
              this.initQrWidget();
              this.executeDSPCounterEvent('login_success');
              setTimeout(async () => {
                this.$router.push('/private');
              }, 400); // 500 мс задержка
              this.removeBodyClass();
            }
          } catch (error) {
            console.error('Error fetching user info', error);
          }
        }, 900); // 500 мс задержка

      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    async register() {
      try {
        const { first_name, last_name, phone_number, email, agree_personal_data, agree_rules, agree_to_advertising } = this.registerForm;

        // Форматирование номера телефона с добавлением префикса +7
        const formattedPhoneNumber = `+${phone_number.replace(/\D/g,'')}`;

        // Проверка полей
        this.errors = {};
        if (first_name.length < 2) {
          this.errors.first_name = 'Введите корректное значение';
        }
        if (last_name.length < 2) {
          this.errors.last_name = 'Введите корректное значение';
        }
        // Пример простой проверки email на наличие символов @ и .
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          this.errors.email = 'Введите корректный email';
        }
        // Пример простой проверки телефона по длине
        if (formattedPhoneNumber.length < 12) {
          this.errors.phone_number = 'Введите корректный телефон';
        }
        if (!agree_personal_data) {
          this.errors.agree_personal_data = 'Необходимо согласиться на обработку персональных данных';
        }
        if (!agree_rules) {
          this.errors.agree_rules = 'Необходимо согласиться с правилами акции';
        }

        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.errors).length > 0) {
          return;
        }

        const utmParamsList = this.utmParams;

        // Отправка запроса на регистрацию с форматированным номером телефона
        await register(first_name, last_name, formattedPhoneNumber, email, JSON.stringify(utmParamsList), agree_to_advertising);

        // Закрываем окно регистрации
        this.closeRegisterFinishPopup();
        this.openRegisterPopupSuccess();

      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    switchToLogin() {
      this.addBodyClass();
      this.showResetPasswordSuccess = false;
      this.showLoginPopup = true;
      this.showRegisterPopup = false;
      this.responseError = '';
    }
  },
  beforeUnmount() {
    // Очищаем таймер при разрушении компонента
    this.stopCheckingForNewChecks();
    window.removeEventListener('scroll', this.handleScroll);
    document.removeEventListener('click', this.handleDynamicButtonClick);
  },
};
</script>


<style lang="scss">
  @import '@/assets/scss/App.scss';
</style>