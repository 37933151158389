<template>
  <div>
    <div :class="['mobile-nav', { 'is-opened': isMobileNavOpened }]">
      <div class="mobile-nav__header">
        <a href="https://jardincoffee.com/" @click="logoLinkClick('jardin')" target="_blank" class="header-logo__jardin">
          <img src="@/assets/img/ui/jardin-logo.png"
               srcset="@/assets/img/ui/jardin-logo@2x.png 2x" alt="">
        </a>

        <a href="https://greenfieldtea.co.uk/en/" @click="logoLinkClick('green')" target="_blank" class="header-logo__greenfield">
          <img src="@/assets/img/ui/greenfield-logo.png"
               srcset="@/assets/img/ui/greenfield-logo@2x.png 2x" alt="">
        </a>

        <a href="https://dostavka.magnit.ru/" @click="logoLinkClick('magnit')" target="_blank" class="header-logo__magnit">
          <img src="@/assets/img/ui/magnit-logo.png"
               srcset="@/assets/img/ui/magnit-logo@2x.png 2x" alt="">
        </a>

        <button @click="toggleMobileNav" class="button mobile-button">
          <img src="@/assets/img/icons/close.svg" alt="">
        </button>
      </div>

      <div class="mobile-nav__list">
        <a href="/#how-register" @click="handleLinkClick('#how-register')">Как принять участие</a>
        <a href="/#prizes" @click="handleLinkClick('#prizes')">Призы</a>
        <a href="/#winners" @click="handleLinkClick('#winners')">Победители</a>
        <a href="/#faq" @click="handleLinkClick('#faq')">FAQ</a>
        <a v-if="mainSettings.rules" v-bind:href="mainSettings.rules" target="_blank" @click="handleRulesButtonClick()">Правила акции</a>
      </div>

      <div class="mobile-nav__footer">
        <button v-if="!user" @click="openLoginPopup" class="button button-main button-main--mini">
          <span>Войти</span>
        </button>
        <template v-else>
          <button class="button button-main button-main--mini" @click="redirectToPrivatePage">
            <span>{{ user.first_name }} {{ user.last_name_initial }}</span>
          </button>
          <button @click="logout" class="button button-logout"></button>
        </template>
      </div>
    </div>

    <header :class="{ 'is-scrolled': isHeaderScrolled }">
      <div class="container">
        <div class="header">
          <div class="header-logo">
            <a href="https://jardincoffee.com/" @click="logoLinkClick('jardin')" target="_blank" class="header-logo__jardin">
              <img src="@/assets/img/ui/jardin-logo.png"
                   srcset="@/assets/img/ui/jardin-logo@2x.png 2x" alt="">
            </a>

            <a href="https://greenfieldtea.co.uk/en/" @click="logoLinkClick('green')" target="_blank" class="header-logo__greenfield">
              <img src="@/assets/img/ui/greenfield-logo.png"
                   srcset="@/assets/img/ui/greenfield-logo@2x.png 2x" alt="">
            </a>

            <a href="https://dostavka.magnit.ru/" @click="logoLinkClick('magnit')" target="_blank" class="header-logo__magnit">
              <img src="@/assets/img/ui/magnit-logo.png"
                   srcset="@/assets/img/ui/magnit-logo@2x.png 2x" alt="">
            </a>
          </div>
          <div class="header-nav">
            <a @click="redirectToHomePage('link')" href="#">Главная</a>
            <a href="/#how-register" @click="SendMetrick('how-register')">Как принять участие</a>
            <a href="/#prizes" @click="SendMetrick('prizes')">Призы</a>
            <a href="/#winners" @click="SendMetrick('winners')">Победители</a>
            <a href="/#faq" @click="SendMetrick('faq')">FAQ</a>
          </div>
          <div class="header-user">
            <button v-if="!user" @click="openLoginPopup" class="button button-main button-main--user">
              <span>Личный кабинет</span>
            </button>
            <template v-else>
              <button class="button button-main button-main--mini" @click="redirectToPrivatePage">
                <span>{{ user.first_name }} {{ user.last_name_initial }}</span>
              </button>
              <button @click="logout" class="button button-logout"></button>
            </template>
          </div>
          <div class="mobile-menu">
            <button @click="toggleMobileNav" class="button">
              <img src="@/assets/img/icons/mobile-menu.svg" alt="">
            </button>
          </div>
        </div>
      </div>
    </header>

    <section id="main" class="section section--clean">
      <img src="@/assets/img/main/hero-main.jpg"
           srcset="@/assets/img/main/hero-main@2x.jpg 2x"
           class="main-bg main-bg--desktop" alt="">

      <div class="promo-date">
        с 20 ноября по 31 декабря 2024 г.
        <!--        {{ promoDates }}-->
      </div>
      <div class="promo-text-block">
        <h1>
          Наполняем жизнь
          <br><strong>вдохновением</strong>
        </h1>

        <button v-if="!user" @click="handleParticipateButton" class="button button-main button-arrow">
          <span>зарегистрировать чек</span>
        </button>
        <template v-else>
          <button class="button button-main button-arrow" @click="triggerQrScan">
            <span>зарегистрировать чек</span>
          </button>
        </template>
      </div>

      <img src="@/assets/img/main/hero-main--mobile.jpg"
           srcset="@/assets/img/main/hero-main--mobile@2x.jpg 2x"
           class="main-bg main-bg--mobile" alt="">
    </section>

    <section id="how-register" class="section js-animate-section">
      <div class="container">
        <div class="section-title">
          Как принять участие?
        </div>
        <swiper
            :modules="modules"
            :slides-per-view="1"
            navigation
            :pagination="{ clickable: true }"
            :breakpoints="{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 24,
                }
              }"
        >
          <swiper-slide>
            <div class="register-step-item">
              <div class="register-step-item__img">
                <img src="@/assets/img/how-register/step-1.png"
                     srcset="@/assets/img/how-register/step-1@2x.png 2x" alt="">
              </div>
              <div class="register-step-item__count">
                1
              </div>
              <div class="register-step-item__title">
                Купите
              </div>
              <div class="register-step-item__text">
                любой кофе Jardin
                <br>или чай Greenfield
                <br>в Магните
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="register-step-item">
              <div class="register-step-item__img">
                <img src="@/assets/img/how-register/step-2.png"
                     srcset="@/assets/img/how-register/step-2@2x.png 2x" alt="">
              </div>
              <div class="register-step-item__count">
                2
              </div>
              <div class="register-step-item__title">
                Зарегистрируйте
              </div>
              <div class="register-step-item__text">
                Чек на сайте

                <button v-if="!user" @click="handleParticipateButton" class="button button-main button-main--mini">
                  <span>зарегистрировать чек</span>
                </button>
                <template v-else>
                  <button class="button button-main button-main--mini" @click="triggerQrScan">
                    <span>зарегистрировать чек</span>
                  </button>
                </template>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="register-step-item">
              <div class="register-step-item__img">
                <img src="@/assets/img/how-register/step-3.png"
                     srcset="@/assets/img/how-register/step-3@2x.png 2x" alt="">
              </div>
              <div class="register-step-item__count">
                3
              </div>
              <div class="register-step-item__title">
                Получите
              </div>
              <div class="register-step-item__text">
                20 или 50 ₽
                <br>гарантированно
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="register-step-item">
              <div class="register-step-item__img">
                <img src="@/assets/img/how-register/step-4.png"
                     srcset="@/assets/img/how-register/step-4@2x.png 2x" alt="">
              </div>
              <div class="register-step-item__count">
                4
              </div>
              <div class="register-step-item__title">
                Участвуйте
              </div>
              <div class="register-step-item__text">
                В розыгрыше
                <br>призов
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </section>

    <section id="prizes" class="section js-animate-section">
      <div class="container">
        <div class="section-title">
          Призы
        </div>
        <div class="prize-list">
          <div class="prize-list-item prize-list-item-main">
            <div class="prize-list-item-main__left">
              <div class="prize-list-item-main__tags">
                <span>Главный приз</span>
                <span class="o-invert">2 шт.</span>
              </div>
              <div class="prize-list-item-main__info">
                Вдохновляющее
                <br>путешествие
              </div>
              <div class="prize-list-item-main__title">
                тур&nbsp;на&nbsp;бали
                <br>на&nbsp;500&nbsp;000&nbsp;₽
              </div>
            </div>
            <div class="prize-list-item-main__right">
              <img src="@/assets/img/prizes/prize-main.png"
                   srcset="@/assets/img/prizes/prize-main@2x.png 2x"
                   class="desktop-only" alt="">

              <img src="@/assets/img/prizes/prize-main--mobile.png"
                   srcset="@/assets/img/prizes/prize-main--mobile@2x.png 2x"
                   class="mobile-only" alt="">
            </div>
          </div>
          <div class="prize-list-item">
            <div class="prize-list-item__tag">
              <span>Гарантированный приз</span>
            </div>
            <div class="prize-list-item__img">
              <img src="@/assets/img/prizes/prize-item-1.png"
                   srcset="@/assets/img/prizes/prize-item-1@2x.png 2x" alt="">
            </div>
            <div class="prize-list-item__title">
              20 ₽ или 50 ₽ на телефон
            </div>
          </div>
          <div class="prize-list-item">
            <InfoBlock>
              <div class="info-block__content">
                Розыгрыш среди покупателей Jardin Americano Crema
              </div>
            </InfoBlock>
            <div class="prize-list-item__tag">
              <span>Специальный приз</span>
            </div>
            <div class="prize-list-item__img">
              <img src="@/assets/img/prizes/prize-item-2.png"
                   srcset="@/assets/img/prizes/prize-item-2@2x.png 2x" alt="">
            </div>
            <div class="prize-list-item__title">
              50 000 ₽ на банковскую карту
            </div>
          </div>
          <div class="prize-list-item prize-list-item--full">
            <div class="prize-list-item__tag">
              <span>Еженедельные призы</span>
            </div>
            <div class="prize-list-mini">
              <div class="prize-list-mini-item">
                <div class="prize-list-mini-item__img">
                  <img src="@/assets/img/prizes/prize-item-3.png"
                       srcset="@/assets/img/prizes/prize-item-3@2x.png 2x" alt="">
                </div>
                <div class="prize-list-mini-item__title">
                  Сертификат
                  <br>Магнит на 4000 ₽
                </div>
                <div class="prize-list-mini-item__count">
                  <span>300 шт.</span>
                </div>
              </div>
              <div class="prize-list-mini-item">
                <div class="prize-list-mini-item__img">
                  <img src="@/assets/img/prizes/prize-item-4.png"
                       srcset="@/assets/img/prizes/prize-item-4@2x.png 2x" alt="">
                </div>
                <div class="prize-list-mini-item__title">
                  Графический
                  <br>планшет
                </div>
                <div class="prize-list-mini-item__count">
                  <span>12 шт.</span>
                </div>
              </div>
              <div class="prize-list-mini-item">
                <div class="prize-list-mini-item__img">
                  <img src="@/assets/img/prizes/prize-item-5.png"
                       srcset="@/assets/img/prizes/prize-item-5@2x.png 2x" alt="">
                </div>
                <div class="prize-list-mini-item__title">
                  Смартфон
                  <br>Samsung Galaxy S24
                </div>
                <div class="prize-list-mini-item__count">
                  <span>24 шт.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="winners" class="section js-animate-section">
      <div class="container">
        <div class="section-title">
          Победители
        </div>

        <div>
          <div class="winners-nav">
            <button
                @click="setPrizeType(1)"
                :class="['winners-nav__btn', { 'active': activePrizeType === 1 }]">
              Еженедельный
            </button>
            <button
                @click="setPrizeType(2)"
                :class="['winners-nav__btn', { 'active': activePrizeType === 2 }]">
              Главный
            </button>
            <button
                @click="setPrizeType(3)"
                :class="['winners-nav__btn', { 'active': activePrizeType === 3 }]">
              Специальный
            </button>
          </div>

          <div v-if="winners.length">
            <div class="winners-list">
              <div v-for="winner in winners" :key="winner.id" class="winners-list-item">
                <div class="winners-list-item__name">{{ winner.winner.name }}</div>
                <div class="winners-list-item__phone">{{ winner.winner.phone_number }}</div>
                <div class="winners-list-item__prize">{{ winner.prize.title }}</div>
                <div class="winners-list-item__img">
                  <img :src="winner.prize.cabinet_image" alt="">
                </div>
              </div>
              <button
                  v-if="hasMoreWinners"
                  @click="loadMoreWinners"
                  class="load-more-button button button-main">Загрузить ещё</button>
            </div>
          </div>
          <p v-else class="winners-list-empty">Победители скоро будут объявлены.</p>
        </div>
      </div>
    </section>

    <section id="faq" class="section js-animate-section">
      <div class="container">
        <div class="section-title">FAQ</div>
        <div class="faq-block">
          <div class="faq-block-item" v-for="faq in faq" :key="faq">
            <button class="faq-block-item__btn" @click="toggleAccordion">
              {{ faq.question }}
            </button>
            <div class="faq-block-item__text" v-html="formatText(faq.answer)"></div>
          </div>
        </div>
      </div>
    </section>

    <section id="contacts" class="section js-animate-section">
      <div class="container">
        <div class="section-title">
          Остались вопросы?
          <br>Напишите нам!
        </div>
        <div class="section-text section-text--center">
          <button class="button button-main button-arrow" @click="triggerQuestionForm">Задать вопрос</button>
        </div>
      </div>
    </section>

    <!-- Popup для входа -->
    <div v-if="showLoginPopup" class="popup">
      <div class="popup__overlay" @click="closeLoginPopup"></div>
      <div class="popup__window">
        <button @click="closeLoginPopup" class="popup__close"></button>
        <div class="popup__title">Авторизация</div>
        <div class="popup__content">
          <form @submit.prevent="login" autocomplete="off">
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="login">Логин</label>
                <input type="text" id="login" v-model="email" name="email" :class="{ 'is-error': loginFormErrors.email }">
                <span v-if="loginFormErrors.email" class="error-message">{{ loginFormErrors.email }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="password">Пароль</label>
                <input
                    :type="passwordVisible ? 'text' : 'password'"
                    maxlength="255"
                    id="password"
                    v-model="password"
                    name="password"
                />
                <span class="password-toggle" @click="togglePasswordVisibility">
                  <i class="far" :class="passwordVisible ? 'fa-eye' : 'fa-eye-slash'"></i>
                </span>
                <span v-if="loginFormErrors.password" class="error-message">{{ loginFormErrors.password }}<br></span>
                <span class="link" @click="openResetPasswordPopup">Забыли пароль?</span>
              </div>
            </div>
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-main button-main--big button-main--full">
                  <span>Войти</span>
                </button>
              </div>
            </div>
            <div class="field-row field-row-account">
              <div class="field-item">
                Нет аккаунта?
                <button class="button button-main" @click="openRegisterPopup" :disabled="state.promoStatus === 'end'">
                  <span>Регистрация</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Popup для регистрации -->
    <div v-if="showRegisterPopup" class="popup">
      <div class="popup__overlay"></div>
      <div class="popup__window">
        <button @click="closeRegisterPopup" class="popup__close"></button>
        <div class="popup__title">Регистрация</div>
        <div class="popup__content">
          <form @submit.prevent="register" autocomplete="off">
            <!-- Поле Имя -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-name">Имя</label>
                <input type="text" maxlength="255" id="reg-name" v-model="registerForm.first_name" @input="removeSpacesFirstName" name="first_name"
                       autocomplete="off"
                       @blur="clearError('first_name')"
                       :class="{ 'is-error': errors.first_name }">
                <span v-if="errors.first_name" class="error-message">{{ errors.first_name }}</span>
              </div>
            </div>
            <!-- Поле Фамилия -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="last-name">Фамилия</label>
                <input type="text" maxlength="255" id="last-name" v-model="registerForm.last_name" @input="removeSpacesLastName" name="last_name"
                       autocomplete="off"
                       @blur="clearError('last_name')"
                       :class="{ 'is-error': errors.last_name }">
                <span v-if="errors.last_name" class="error-message">{{ errors.last_name }}</span>
              </div>
            </div>
            <!-- Поле Номер телефона -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-phone">Номер телефона</label>
                <input type="text" maxlength="18" id="reg-phone" v-model="registerForm.phone_number" name="phone_number"
                       autocomplete="off"
                       @blur="clearError('phone_number')"
                       :class="{ 'is-error': errors.phone_number }" v-mask>
                <span v-if="errors.phone_number" class="error-message">{{ errors.phone_number }}</span>
              </div>
            </div>
            <!-- Поле Email -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-email">Email</label>
                <input type="text" maxlength="255" id="reg-email" v-model="registerForm.email" name="email"
                       autocomplete="off"
                       @blur="clearError('email')"
                       :class="{ 'is-error': errors.email }">
                <span v-if="errors.email" class="error-message">{{ errors.email }}</span>
              </div>
            </div>
            <!-- Чекбокс согласия с обработкой персональных данных -->
            <div class="field-row field-row--compact" data-cols="1">
              <div class="field-item field-item-checkbox">
                <label for="reg-personality">
                  <input type="checkbox" id="reg-personality" v-model="registerForm.agree_personal_data" @blur="clearError('agree_personal_data')">
                  <span>Согласен(а) <a href="/Согласие_субъекта_на_обработку_персональных_данных.pdf" target="_blank" class="link">на обработку персональных данных</a></span>
                </label>
                <span v-if="errors.agree_personal_data" class="error-message">{{ errors.agree_personal_data }}</span>
              </div>
            </div>
            <!-- Чекбокс согласия с правилами акции -->
            <div class="field-row field-row--compact" data-cols="1">
              <div class="field-item field-item-checkbox">
                <label for="reg-rules">
                  <input type="checkbox" id="reg-rules" v-model="registerForm.agree_rules" @blur="clearError('agree_rules')">
                  <span>Согласен(на) <a class="link" v-if="mainSettings.rules" v-bind:href="mainSettings.rules" target="_blank" @click="handleRulesButtonClick()">с правилами акции</a></span>
                </label>
                <span v-if="errors.agree_rules" class="error-message">{{ errors.agree_rules }}</span>
              </div>
            </div>
            <!-- Чекбокс согласия с правилами акции -->
            <div class="field-row field-row--compact" data-cols="1">
              <div class="field-item field-item-checkbox">
                <label for="reg-partners">
                  <input type="checkbox" id="reg-partners" v-model="registerForm.agree_to_advertising">
                  <span>Я согласен(на) на получение персональных предложений от партнера</span>
                </label>
              </div>
            </div>
            <!-- Кнопка для отправки формы регистрации -->
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit"
                        class="button button-main button-main--big button-main--full"
                        :disabled="state.promoStatus === 'end'">
                  <span>Регистрация</span>
                </button>
              </div>
            </div>
            <!-- Кнопка для переключения на окно входа -->
            <div class="field-row field-row-account">
              <div class="field-item">
                Уже есть аккаунт?
                <button class="button button-main" @click="switchToLogin">
                  <span>Войти</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showRegisterPopupSuccess" class="popup">
      <div class="popup__overlay" @click="closeRegisterPopupSuccess"></div>
      <div class="popup__window">
        <button @click="closeRegisterPopupSuccess" class="popup__close"></button>
        <div class="popup__content">
          Спасибо за регистрацию.
          <br>Пароль для входа отправлен на&nbsp;email.
          <br>Проверь, пожалуйста, папку спам.
        </div>
      </div>
    </div>

    <!-- Popup успешной отправки вопроса -->
    <div v-if="showQuestionPopup" class="popup">
      <div class="popup__overlay" @click="closeQuestionPopup"></div>
      <div class="popup__window">
        <button @click="closeQuestionPopup" class="popup__close"></button>
        <div class="popup__title">Обратная связь</div>
        <div class="popup__content">
          <form @submit.prevent="submitQuestion" autocomplete="off">
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="feedback-name">Имя</label>
                <input
                    type="text"
                    v-model="questionForm.name"
                    id="feedback-name"
                    name="name"
                    @input="removeSpacesName"
                    :disabled="isNameFieldDisabled" />
                <span v-if="feedbackErrors.name" class="error-message">{{ feedbackErrors.name }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="feedback-email">Email</label>
                <input
                    type="text"
                    v-model="questionForm.email"
                    id="feedback-email"
                    name="email"
                    :disabled="user && !!user.email" />
                <span v-if="feedbackErrors.email" class="error-message">{{ feedbackErrors.email }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="feedback-subject">Тема обращения</label>
                <select name="subject"
                        id="feedback-subject"
                        v-model="questionForm.subject">
                  <option value="" disabled selected>Выберите тему</option>
                  <option value="0">Чек отклонен</option>
                  <option value="1">Личные данные</option>
                  <option value="2">Призы</option>
                  <option value="3">Другое</option>
                </select>
                <span v-if="feedbackErrors.subject" class="error-message">{{ feedbackErrors.subject }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="feedback-message">Сообщение</label>
                <textarea v-model="questionForm.text" id="feedback-message" name="text"></textarea>
                <span v-if="feedbackErrors.text" class="error-message">{{ feedbackErrors.text }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="files" class="attach-file">Прикрепить файл</label>
                <input
                    type="file"
                    id="files"
                    name="files"
                    multiple
                    @change="handleFileChange"
                    accept=".jpg, .jpeg, .png, .gif, .tiff, .webp, .pdf">
                <div class="attach-info">
                  <p>Разрешенные форматы файлов: .jpg, .jpeg, .png, .gif, .tiff, .webp, .pdf.
                    <br>Максимальный размер файла: 500 MB.
                    <br>Максимальное количество файлов: 10.</p>
                </div>


                <!-- Отображение превью файлов -->
                <div class="file-preview" v-if="filePreviews.length">
                  <div v-for="(file, index) in filePreviews" :key="index" class="file-preview-item">
                    <div v-if="file.type.startsWith('image')" class="file-preview-item__img">
                      <img :src="file.preview" :alt="file.name" />
                    </div>
                    <button @click="removeFile(index)" class="remove-file-btn"></button>
                  </div>
                </div>
              </div>
            </div>

            <div class="field-row" data-cols="1">
              <div class="field-item field-item-checkbox">
                <label for="feedback-personality">
                  <input type="checkbox" id="feedback-personality" v-model="questionForm.agree_personal_data">
                  <span>Согласен(а) <a href="/Согласие_субъекта_на_обработку_персональных_данных.pdf" target="_blank" class="link">на обработку персональных данных</a></span>
                </label>
                <span v-if="feedbackErrors.agree_personal_data" class="error-message">{{ feedbackErrors.agree_personal_data }}</span>
              </div>
            </div>
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-main button-main--full">Отправить</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showQuestionPopupSuccess" class="popup">
      <div class="popup__overlay" @click="closeQuestionPopupSuccess"></div>
      <div class="popup__window">
        <button @click="closeQuestionPopupSuccess" class="popup__close"></button>
        <div class="popup__content">
          <div class="success-message">
            Спасибо за обращение!
            <br>Ваш вопрос будет обработан в течение 72 часов.
          </div>
        </div>
      </div>
    </div>

    <!-- Popup для восстановления пароля -->
    <div v-if="showResetPasswordPopup" class="popup">
      <div class="popup__overlay" @click="closeResetPasswordPopup"></div>
      <div class="popup__window">
        <button @click="closeResetPasswordPopup" class="popup__close"></button>
        <div class="popup__title">Восстановление пароля</div>
        <div class="popup__content">
          <form @submit.prevent="submitResetPassword" autocomplete="off">
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-phone">Введите email</label>
                <input type="text" id="reg-phone" v-model="resetPasswordForm.email" name="email"
                       placeholder="Введите свой email"
                       :class="{ 'is-error': resetPasswordErrors.email }">
                <span v-if="resetPasswordErrors.email" class="error-message">{{ resetPasswordErrors.email }}</span>
              </div>
            </div>
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-main button-main--big button-main--full">
                  <span>Отправить</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showResetPasswordSuccess" class="popup">
      <div class="popup__overlay" @click="closeResetPasswordPopupSuccess"></div>
      <div class="popup__window">
        <button @click="closeResetPasswordPopupSuccess" class="popup__close"></button>
        <div class="popup__title">
          Восстановление пароля
        </div>
        <div class="popup__content">
            <p>Новый пароль был отправлен на указанный вами email</p>
          <p>
            <button class="button button-main button-main--big button-main--full" @click="switchToLogin">
              <span>Войти</span>
            </button>
          </p>
        </div>
      </div>
    </div>

    <!-- Popup статусов акции -->
    <div v-if="showPromoNotStarted" class="popup">
      <div class="popup__overlay" @click="closeShowPromoNotStarted"></div>
      <div class="popup__window">
        <button @click="closeShowPromoNotStarted" class="popup__close"></button>
        <div class="popup__content">
            Акция ещё не началась
        </div>
      </div>
    </div>
    <div v-if="showPromoEnd" class="popup">
      <div class="popup__overlay" @click="closeShowPromoEnd"></div>
      <div class="popup__window">
        <button @click="closeShowPromoEnd" class="popup__close"></button>
        <div class="popup__title" style="margin-bottom: 1rem;">
          Акция завершена.
          <br>Спасибо за участие!
        </div>
        <div class="popup__content">
          Вручение призов осуществляется до&nbsp;20&nbsp;января&nbsp;2025&nbsp;г.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global ym */
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper/modules';
import InfoBlock from '@/components/infoBlock.vue';
import { fetchFAQ, getToken, getUserInfo, removeToken, authorize, register, getMainSettings, sendFeedback, setAuthHeader, sendResetPassword, getWinnings } from '@/api';
import metrikaMixin from '@/mixins/metrikaMixin';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { state } from "@/store";

export default {
  name: 'App',
  mixins: [metrikaMixin],
  components: {
    Swiper,
    SwiperSlide,
    InfoBlock
  },
  setup() {
    return {
      modules: [ Pagination ],
      state,
    };
  },
  data() {
    return {
      utmParams: {},
      clientId: null,
      winners: [],
      limit: 20,
      offset: 0,
      hasMoreWinners: true,
      isLoading: false,
      activePrizeType: 1,
      isHeaderScrolled: false,
      scrollThreshold: 0,
      rootUrl: window.location.origin,
      userId: '',
      userUuid: '',
      isMobileNavOpened: false,
      faq: [],
      prizes: [],
      user: null,
      showLoginPopup: false,
      showRegisterPopup: false,
      showRegisterPopupSuccess: false,
      showQuestionPopup: false,
      showQuestionPopupSuccess: false,
      showPromoNotStarted: false,
      showPromoEnd: false,
      showEndPopupSuccess: false,
      showResetPasswordPopup: false,
      showResetPasswordSuccess: false,
      AccountPopup: false,
      questionForm: {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false,
        subject: '',
        files: []
      },
      filePreviews: [],
      phone_number: '',
      password: '',
      passwordVisible: false,
      registerForm: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        agree_personal_data: false,
        agree_rules: false,
        agree_to_advertising: false
      },
      loginForm: {
        email: '',
        password: '',
      },
      loginFormErrors: {},
      resetPasswordForm: {
        phone_number: '',
      },
      feedbackErrors: {},
      resetPasswordErrors: {},
      errors: {},
      responseError: '',
      days: 0,
      hours: 0,
      minutes: 0,
      timerMessage: '',
      promoDates: '',
      mainSettings: {},
    };
  },
  async created() {
    try {
      this.mainSettings = await getMainSettings();
    } catch (error) {
      console.error('Failed to fetch main settings', error);
    }
    this.loadFAQ();
    this.checkAuthentication();
    this.fetchTimerData();
  },
  mounted() {
    const promoShown = sessionStorage.getItem('promoShown');
    if (!promoShown) {
      this.showPromoEnd = true; // Показываем попап
      sessionStorage.setItem('promoShown', 'true'); // Помечаем, что попап был показан
    }
    this.addIntersectionObserver();
    this.getClientId();
    this.getUTMParams();
    this.handleScroll();
    this.fetchWinners();
    this.fetchPrizes();
    setAuthHeader();
    const token = getToken();
    if (token) {
      setTimeout(async () => {
        getUserInfo().then(userInfo => {
          if (userInfo) {
            this.userId = userInfo.id;
            this.userUuid = userInfo.uuid;
            this.user = {
              first_name: userInfo.first_name,
              last_name_initial: userInfo.last_name.charAt(0),
            };
            this.questionForm.name = userInfo.first_name;
            this.questionForm.email = userInfo.email;
            // this.initQrWidget();
          }
        }).catch(error => {
          console.error('Failed to fetch user info on mount', error);
        });
      }, 900); // 500 мс задержка
    }
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    formattedPhoneNumber() {
      // Убираем знак '+' и все нецифровые символы
      const cleaned = this.phoneNumber.replace(/\D/g, '');

      // Проверяем, что номер начинается с '7' и содержит 11 цифр
      if (cleaned.length === 11 && cleaned.startsWith('7')) {
        return `+7 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7, 9)}-${cleaned.slice(9, 11)}`;
      }

      return ' ';
    }
  },
  methods: {
    getClientId() {
      if (typeof ym !== 'undefined') {
        ym(98788876, 'getClientID', (clientId) => {
          this.clientId = clientId;
          this.getUTMParams();
        });
      } else {
        console.error('Yandex.Metrica не доступна');
      }
    },
    executeAdriverEvent(page) {
      const sidMapping = {
        faq: 'click_faq',
        prizes: 'click_prizes',
        winners: 'click_winners',
        register_user_closed: 'abandoned_user_registration_close_form',
        check_upload_closed: 'abandoned_check_registration_close_form',
        click_greenfield: 'click_greenfield',
        click_jardin: 'click_jardin',
        click_magnit: 'click_magnit',
        click_home: 'click_main',
        upload_check_open: 'click_register_check',
        click_how_use: 'click_how_participate',
        click_account: 'click_lk',
        register_user_success: 'successful_user_registration',
        timer_15: 'timer_15'
      };

      if (!sidMapping[page]) return;

      const sid = 229236; // ваш статический sid
      const bt = 62; // ваш статический bt
      const sz = sidMapping[page];

      // Вызов Adriver-кода
      (function (e, n) {
        function o(e, n, o) {
          n = n || "&";
          o = o || "=";
          var d = [];
          for (var r in e)
            if (Object.prototype.hasOwnProperty.call(e, r)) {
              d.push(r + o + encodeURIComponent(e[r]));
            }
          return d.join(n);
        }
        function d(e, n) {
          var o = e.cookie.match("(^|;) ?" + n + "=([^;]*)(;|$)");
          return o ? decodeURIComponent(o[2]) : null;
        }
        var r,
            t,
            i,
            c,
            u;
        (r = e),
            (t = n),
            (i = document.domain),
            (c = { tail256: document.referrer || "unknown" }),
        void 0 !==
        (u = (function (e) {
          var n = {};
          if (e) {
            var o = e.split("&");
            for (var d in o)
              if (Object.prototype.hasOwnProperty.call(o, d)) {
                var r = o[d].split("=");
                void 0 !== r[0] &&
                void 0 !== r[1] &&
                (n[r[0]] = decodeURIComponent(r[1]));
              }
          }
          return n;
        })(window.location.search.substring(1))).adrclid && (r.fsid = u.adrclid),
        null !== d(document, "adrcid") && (r.cid = d(document, "adrcid")),
        t &&
        t.id &&
        null !== d(document, t.id) &&
        (r.suid = i + "_" + encodeURIComponent(d(document, t.id))),
            t && t.gid1
                ? (r.gid1 = t.gid1)
                : null !== d(document, "_ga") &&
                (r.gid1 = encodeURIComponent(d(document, "_ga"))),
            t && t.yid1
                ? (r.yid1 = t.yid1)
                : null !== d(document, "_ym_uid") &&
                (r.yid1 = encodeURIComponent(d(document, "_ym_uid"))),
            (r.loc = encodeURIComponent(window.location.href)),
        r.custom && (r.custom = o(r.custom, ";")),
            (function (e, n) {
              !(function (e) {
                if (
                    ((e = e.split("![rnd]").join(~~(1e6 * Math.random()))),
                    document.createElement && document.body)
                ) {
                  var n = document.createElement("img");
                  (n.style.position = "absolute"),
                      (n.style.display = "none"),
                      (n.style.width = n.style.height = "0px"),
                      n.setAttribute("referrerpolicy", "no-referrer-when-downgrade"),
                      (n.src = e),
                      document.body.appendChild(n);
                } else {
                  var o = new Image();
                  o.setAttribute("referrerpolicy", "no-referrer-when-downgrade"),
                      (o.src = e);
                }
              })(
                  "https://ad.adriver.ru/cgi-bin/rle.cgi?" +
                  e +
                  "&rnd=![rnd]" +
                  (n ? "&" + n : "")
              );
            })(o(r), o(c));
      })({ sid: sid, bt: bt, sz: sz }, { id: "", gid1: "", yid1: "" });
    },
    executeDSPCounterEvent(siteArea) {
      const sid = 229303; // ваш статический sid
      const siteAreaMapping = {
        '30_sec': 'TJCuegy2',
        'login_success': 'qh1jImpT',
        'check_upload_success': 'hAf9oUO0',
        'buttons_click': 'bG46a4ie',
        'faq_click': 'USZY4vWn',
      };
      const siteAreaId = siteAreaMapping[siteArea] || 'USZY4vWn';

      // Проверка и отложенный вызов если DSPCounter еще не загружен
      const checkDSPCounter = setInterval(() => {
        if (window.DSPCounter) {
          clearInterval(checkDSPCounter);
          window.DSPCounter('send', {
            'sid': sid,
            'site_area': siteAreaId,
            'user_id': '',
            'lead_id': '',
            'order_sum': ''
          });
        }
      }, 100); // проверяем каждые 100ms

    },
    SendMetrick(page) {
      if (page == 'how-register') {
        this.$sendMetrik('reachGoal', 'click_how_use');
        this.executeAdriverEvent('click_how_use');
      } else if (page == 'faq') {
        this.$sendMetrik('reachGoal', 'click_faq');
        this.executeAdriverEvent('faq');
      } else if (page == 'prizes') {
        this.$sendMetrik('reachGoal', 'click_prize');
        this.executeAdriverEvent('prizes');
      } else if (page == 'winners') {
        this.$sendMetrik('reachGoal', 'click_winners');
        this.executeAdriverEvent('winners');
      }
    },
    addIntersectionObserver() {
      const options = {
        root: null, // используется viewport
        threshold: 0.10, // элемент должен быть виден на 25%
      };

      const observer = new IntersectionObserver(this.handleIntersection, options);

      // Находим все элементы с классом .js-animate-section
      const sections = document.querySelectorAll('.js-animate-section');

      // Наблюдаем за каждым элементом
      sections.forEach(section => {
        observer.observe(section);
      });
    },
    handleIntersection(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('is-animated');
        }
      });
    },
    getUTMParams() {
      const params = new URLSearchParams(window.location.search);
      const utmParams = {
        utm_source: params.get('utm_source'),
        utm_medium: params.get('utm_medium'),
        utm_campaign: params.get('utm_campaign'),
        utm_term: params.get('utm_term'),
        utm_content: params.get('utm_content'),
        click_id: params.get('click_id')
      };

      // Фильтрация null значений
      this.utmParams = Object.fromEntries(
          Object.entries(utmParams).filter(([, value]) => value !== null)
      );

      // Добавляем clientId в utmParams после его получения
      if (this.clientId) {
        this.utmParams.client_id = this.clientId;
      }
    },
    handleFileChange(event) {
      const files = Array.from(event.target.files);

      // Фильтрация по разрешённым типам файлов и размеру
      const validFiles = files.filter(file => {
        const isValidType = /\.(jpg|jpeg|png|gif|tiff|webp|pdf)$/i.test(file.name);
        const isValidSize = file.size <= 500 * 1024 * 1024; // 500 MB
        return isValidType && isValidSize;
      });

      // Ограничение на 10 файлов
      if (validFiles.length + this.filePreviews.length > 10) {
        alert("Нельзя прикрепить больше 10 файлов.");
        return;
      }

      // Добавляем файлы в состояние и показываем их превью
      validFiles.forEach(file => {
        const preview = {
          file,
          name: file.name,
          type: file.type,
          preview: URL.createObjectURL(file) // для изображений
        };
        this.filePreviews.push(preview);
        this.questionForm.files.push(file);
      });
    },

    // Удаление файла
    removeFile(index) {
      // Удаляем файл из массива файлов
      this.filePreviews.splice(index, 1);
      this.questionForm.files.splice(index, 1);
    },

    async fetchWinners() {
      try {
        if (this.isLoading || !this.hasMoreWinners) return;

        this.isLoading = true;

        // Запрос к API
        const data = await getWinnings(this.limit, this.offset, this.activePrizeType);

        // Добавляем новые данные
        this.winners = [...this.winners, ...data.results];

        // Проверяем, остались ли ещё данные
        if (data.results.length < this.limit) {
          this.hasMoreWinners = false; // Если пришло меньше, чем limit
        }

        // Увеличиваем offset для следующей загрузки
        this.offset += this.limit;
      } catch (error) {
        console.error('Failed to load winners:', error);
      } finally {
        this.isLoading = false;
      }
    },
    loadMoreWinners() {
      if (!this.isLoading) {
        this.fetchWinners();
      }
    },
    async setPrizeType(prizeType) {
      // Если выбранный тип уже активен, ничего не делаем
      if (this.activePrizeType === prizeType) return;

      // Обновляем активный тип приза
      this.activePrizeType = prizeType;

      // Сбрасываем состояние пагинации
      this.resetPagination();

      // Загружаем данные для нового типа приза
      await this.fetchWinners();
    },
    resetPagination() {
      this.winners = [];
      this.offset = 0;
      this.hasMoreWinners = true;
    },
    handleRulesButtonClick() {
      // this.$sendMetrik('reachGoal', 'Rules_Click');
    },
    addBodyClass() {
      document.body.classList.add('is-popup-opened');
    },
    removeBodyClass() {
      document.body.classList.remove('is-popup-opened');
    },
    async logout() {
      state.checkSoon = false;
      state.promoStatus = '';
      state.checksTotal = 0;
      state.checks = {
        count: 0,
        next: null,
        previous: null,
        results: [],
      };
      state.user = {
        first_name: null,
        last_name: null,
        phone_number: null,
        email: null
      };
      const widget = document.getElementById('apm-widget');
      if (widget) {
        widget.innerHTML = ''; // Очистка содержимого виджета
      }

      this.toggleMobileNav(); // Логика для переключения навигации
      removeToken(); // Удаление токена (например, из localStorage)
      this.user = null; // Обновление состояния пользователя
      this.questionForm = { // Очистка формы
        name: '',
        email: '',
        text: '',
        agree_personal_data: false
      };

      // Ожидание завершения обновления DOM
      await this.$nextTick();

      // Выполняем перенаправление после обновления DOM
      window.location.href = '/';
    },
    async fetchPrizes() {
      try {
        const data = await getWinnings();
        this.prizes = data.results;
      } catch (error) {
        console.error('Error fetching prizes:', error);
      }
    },
    getPrizeTypeText(type) {
      switch (type) {
        case 2:
          return 'Главный';
        case 1:
          return 'Еженедельный';
        case 0:
        default:
          return 'Специальный';
      }
    },
    getPrizeTag(type) {
      return type === 2 ? 'mini-tag--main' : '';
    },
    getPrizeClass(type) {
      switch (type) {
        case 2:
          return 'winners-list-item--main';
        case 1:
          return 'winners-list-item--weekly';
        case 0:
        default:
          return 'winners-list-item--special';
      }
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    formatText(text) {
      // Замена переносов строк на <br>
      let formattedText = text.replace(/\r?\n/g, '<br>');

      // Регулярное выражение для поиска URL, которые НЕ находятся внутри <a> тегов
      const urlPattern = /(?<!<a href=")(https?:\/\/[^\s]+)(?!")/g;

      // Замена URL на HTML-ссылки
      formattedText = formattedText.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');

      return formattedText;
    },
    clearError(field) {
      this.errors = { ...this.errors, [field]: null };
    },
    redirectToPrivatePage() {
      this.$sendMetrik('reachGoal', 'click_account');
      this.executeAdriverEvent('click_account');
      this.$router.push('/private');
      this.isMobileNavOpened = false;
    },
    redirectToHomePage(page) {
      if (page == 'link') {
        this.$sendMetrik('reachGoal', 'click_home');
        this.executeAdriverEvent('click_home');
      }
      this.$router.push('/');
    },
    logoLinkClick(page) {
      if (page == 'magnit') {
        this.$sendMetrik('reachGoal', 'click_magnit');
        this.executeAdriverEvent('click_magnit');
      } else if (page == 'jardin') {
        this.$sendMetrik('reachGoal', 'click_jardin');
        this.executeAdriverEvent('click_jardin');
      } else if (page == 'green') {
        this.$sendMetrik('reachGoal', 'click_greenfield');
        this.executeAdriverEvent('click_greenfield');
      }
    },
    removeSpacesFirstName() {
      const input = event.target.value.trimStart();
      this.registerForm.first_name = input.length > 0 ? input : '';
    },
    removeSpacesLastName() {
      const input = event.target.value.trimStart();
      this.registerForm.last_name = input.length > 0 ? input : '';
    },
    removeSpacesName() {
      const input = event.target.value.trimStart();
      this.questionForm.name = input.length > 0 ? input : '';
    },
    triggerQrScan() {
      this.executeDSPCounterEvent('buttons_click');
      if (state.promoStatus == 'start') {
        const qrElement = document.getElementById('apm-scan-qr');
        if (qrElement) {
          this.$sendMetrik('reachGoal', 'upload_check_open');
          this.executeAdriverEvent('upload_check_open');
          qrElement.click();
        }
      } else if (state.promoStatus == 'not-started') {
        this.addBodyClass();
        this.isMobileNavOpened = false;
        this.showPromoNotStarted = true;
      } else if (state.promoStatus == 'end') {
        this.addBodyClass();
        this.isMobileNavOpened = false;
        this.showPromoEnd = true;
      }
    },
    triggerQuestionForm() {
      const qrElement = document.getElementById('triggerQuestionForm');
      if (qrElement) {
        qrElement.click();
      }
    },
    toggleMobileNav() {
      this.isMobileNavOpened = !this.isMobileNavOpened;
    },
    handleLinkClick(selector) {
      this.toggleMobileNav(); // Закрываем навигацию
      // Убираем символ "#" из selector перед отправкой в SendMetrick
      const cleanSelector = selector.replace('#', '');
      this.SendMetrick(cleanSelector);

      setTimeout(() => { // Добавляем небольшую задержку перед скроллом
        this.scrollToSection(selector);
      }, 300); // Задержка в 300 мс или на ваше усмотрение
    },
    scrollToSection(selector) {
      const target = document.querySelector(selector);
      if (target) {
        const offsetTop = target.getBoundingClientRect().top + window.pageYOffset - 50;
        window.scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        });
      }
    },
    openAccountPopup() {
      this.addBodyClass();
      this.AccountPopup = true;
    },
    closeAccountPopup() {
      this.removeBodyClass();
      this.AccountPopup = false;
    },
    openQuestionPopup() {
      this.addBodyClass();
      this.showQuestionPopup = true;
      this.questionForm.name = state.user.first_name;
      this.questionForm.email = state.user.email;
    },
    closeQuestionPopup() {
      this.removeBodyClass();
      this.showQuestionPopup = false;
      this.resetForm();
    },
    openQuestionPopupSuccess() {
      this.addBodyClass();
      this.showQuestionPopupSuccess = true;
    },
    closeQuestionPopupSuccess() {
      this.removeBodyClass();
      this.showQuestionPopupSuccess = false;
      this.resetForm();
    },
    closeEndPopupSuccess() {
      this.removeBodyClass();
      this.showEndPopupSuccess = false;
      this.resetForm();
    },
    openResetPasswordPopup() {
      this.showResetPasswordPopup = true;
      this.closeLoginPopup();
      this.addBodyClass();
    },
    closeResetPasswordPopup() {
      this.removeBodyClass();
      this.showResetPasswordPopup = false;
      this.resetForm();
    },
    openResetPasswordPopupSuccess() {
      this.addBodyClass();
      this.showResetPasswordSuccess = true;
    },
    closeResetPasswordPopupSuccess() {
      this.removeBodyClass();
      this.showResetPasswordSuccess = false;
      this.resetForm();
    },
    closeShowPromoNotStarted() {
      this.removeBodyClass();
      this.showPromoNotStarted = false;
    },
    closeShowPromoEnd() {
      this.removeBodyClass();
      this.showPromoEnd = false;
    },
    async submitQuestion() {
      try {
        this.feedbackErrors = {};

        // Валидация формы
        if (!this.questionForm.name) {
          this.feedbackErrors.name = 'Введите имя';
        }
        if (!this.questionForm.email) {
          this.feedbackErrors.email = 'Введите email';
        }
        if (!this.questionForm.text) {
          this.feedbackErrors.text = 'Введите сообщение';
        }
        if (!this.questionForm.agree_personal_data) {
          this.feedbackErrors.agree_personal_data = 'Необходимо согласиться на обработку персональных данных';
        }
        if (!this.questionForm.subject || this.questionForm.subject === 'Выберите тему') {
          this.feedbackErrors.subject = 'Выберите тему';
        }

        if (Object.keys(this.feedbackErrors).length > 0) {
          return;
        }

        // Отправка данных формы через API
        await sendFeedback({
          email: this.questionForm.email,
          name: this.questionForm.name,
          text: this.questionForm.text,
          subject: this.questionForm.subject,
          files: this.questionForm.files // отправляем файлы
        });

        this.closeQuestionPopup();
        this.openQuestionPopupSuccess();
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    async submitResetPassword() {
      try {
        const { email } = this.resetPasswordForm;
        this.resetPasswordErrors = {};

        // Валидация длины телефонного номера
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          this.resetPasswordErrors.email = 'Введите корректный email';
        }

        // Отправка данных формы через API
        await sendResetPassword({ email: email });

        // Успешная отправка, здесь можно закрыть попап
        this.closeResetPasswordPopup();
        this.openResetPasswordPopupSuccess();
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    resetForm() {
      this.questionForm = {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false
      };

      this.phone_number = '';
      this.password = '';

      this.registerForm = {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        agree_personal_data: false,
        agree_rules: false,
        agree_to_advertising: false
      };

      this.resetPasswordForm = {
        phone_number: '',
      };

      this.feedbackErrors = {};
    },
    toggleAccordion(event) {
      this.executeDSPCounterEvent('faq_click');
      event.target.classList.toggle('is-opened');
      const textBlock = event.target.nextElementSibling;
      textBlock.classList.toggle('is-opened');
    },
    handleScroll() {
      this.isHeaderScrolled = window.scrollY > this.scrollThreshold;
    },
    async checkAuthentication() {
      const token = getToken();
      if (token) {
        try {
          const userInfo = await getUserInfo();
          this.user = userInfo;
          state.user = {
            first_name: userInfo.first_name,
            last_name: userInfo.last_name,
            phone_number: userInfo.phone_number,
            email: userInfo.email
          }
          this.executeDSPCounterEvent('login_success');
          // this.questionForm.name = userInfo.first_name;
          // this.questionForm.email = userInfo.email;
        } catch (error) {
          if (error.response && error.response.data) {
            this.responseError = error.response.data.detail;
          }
        }
      }
    },
    async fetchTimerData() {
      try {
        const { start_date, end_date } = await getMainSettings();

        // Текущее время
        const now = new Date();

        const date = new Date(end_date);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();

        // Преобразуем строки дат в объекты Date
        const startDate = new Date(start_date);
        const endDate = new Date(end_date);

        // Увеличиваем дату окончания на 1 день и устанавливаем время на начало следующего дня
        endDate.setUTCDate(endDate.getUTCDate() + 1);
        endDate.setUTCHours(0, 0, 0, 0);

        // Форматируем даты для отображения в локальном времени
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const start = startDate.toLocaleDateString('ru-RU', options);
        // const end = endDate.toLocaleDateString('ru-RU', options);
        this.promoDates = `${start} – ${day}.${month}.${year}`;

        // Проверяем текущее время относительно стартовой и конечной даты
        if (now < startDate) {
          // Если акция еще не началась
          this.calculateTimeDifference(now, startDate);
          this.timerMessage = 'до начала акции';
        } else if (now >= startDate && now < endDate) {
          // Если акция идет в данный момент
          this.calculateTimeDifference(now, endDate);
          this.timerMessage = 'До окончания приема чеков';
        } else {
          // Если Акция завершена
          this.days = 0;
          this.hours = 0;
          this.minutes = 0;
          this.timerMessage = 'Акция завершилась';
        }
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },

    calculateTimeDifference(start, end) {
      const diff = end - start;

      // Переводим разницу в миллисекундах в дни, часы и минуты
      const totalSeconds = Math.floor(diff / 1000);
      this.days = Math.floor(totalSeconds / (3600 * 24));
      this.hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
      this.minutes = Math.floor((totalSeconds % 3600) / 60);
    },
    handleParticipateButton() {
      this.openLoginPopup();
    },
    openLoginPopup() {
      this.$sendMetrik('reachGoal', 'click_account');
      this.executeAdriverEvent('click_account');
      if (state.promoStatus == 'start' || state.promoStatus == 'end') {
        this.addBodyClass();
        this.isMobileNavOpened = false;
        this.responseError = '';
        this.showLoginPopup = true;
      } else if (state.promoStatus == 'not-started') {
        this.addBodyClass();
        this.isMobileNavOpened = false;
        this.responseError = '';
        this.showPromoNotStarted = true;
      }
    },
    closeLoginPopup() {
      this.removeBodyClass();
      this.showLoginPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    openRegisterPopup() {
      this.showRegisterPopup = true;
      this.closeLoginPopup();
      this.responseError = '';
      this.addBodyClass();
    },
    closeRegisterPopup() {
      this.$sendMetrik('reachGoal', 'register_user_closed');
      this.executeAdriverEvent('register_user_closed');
      this.removeBodyClass();
      this.showRegisterPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    closeRegisterFinishPopup() {
      this.addBodyClass();
      this.showRegisterPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    openRegisterPopupSuccess() {
      this.$sendMetrik('reachGoal', 'register_user_success');
      this.executeAdriverEvent('register_user_success');
      this.addBodyClass();
      this.showRegisterPopupSuccess = true;
      this.responseError = '';
    },
    closeRegisterPopupSuccess() {
      this.removeBodyClass();
      this.showRegisterPopupSuccess = false;
      this.responseError = '';
      this.resetForm();
    },
    async loadFAQ() {
      try {
        const data = await fetchFAQ();
        this.faq = data.results.map(item => ({
          question: item.question,
          answer: item.answer.replace("{rules_link}", this.mainSettings.rules)
        }));
      } catch (error) {
        console.error('Error fetching FAQ:', error);
      }
    },
    async login() {
      try {
        this.loginFormErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(this.email)) {
          this.loginFormErrors.email = 'Введите корректный email';
        }
        if (this.password.length < 2) {
          this.loginFormErrors.password = 'Введите пароль';
        }
        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.loginFormErrors).length > 0) {
          return;
        }

        await authorize(this.email, this.password);
        setAuthHeader();
        setTimeout(async () => {
          try {
            const userInfo = await getUserInfo();
            if (userInfo) {
              this.userId = userInfo.id;
              this.userUuid = userInfo.uuid;
              this.user = {
                first_name: userInfo.first_name,
                last_name_initial: userInfo.last_name.charAt(0),
              };
              this.questionForm.name = userInfo.first_name;
              this.questionForm.email = userInfo.email;
              this.closeLoginPopup();
              this.removeBodyClass();
              // this.initQrWidget();
              this.executeDSPCounterEvent('login_success');
              setTimeout(() => {
                this.$router.push('/private');
              }, 500)
            }
          } catch (error) {
            console.error('Error fetching user info', error);
          }
        }, 900); // 500 мс задержка

      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    async register() {
      try {
        const { first_name, last_name, phone_number, email, agree_personal_data, agree_rules, agree_to_advertising } = this.registerForm;

        // Форматирование номера телефона с добавлением префикса +7
        const formattedPhoneNumber = `+${phone_number.replace(/\D/g,'')}`;

        // Проверка полей
        this.errors = {};
        if (first_name.length < 2) {
          this.errors.first_name = 'Введите корректное значение';
        }
        if (last_name.length < 2) {
          this.errors.last_name = 'Введите корректное значение';
        }
        // Пример простой проверки email на наличие символов @ и .
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          this.errors.email = 'Введите корректный email';
        }
        // Пример простой проверки телефона по длине
        if (formattedPhoneNumber.length < 12) {
          this.errors.phone_number = 'Введите корректный телефон';
        }
        if (!agree_personal_data) {
          this.errors.agree_personal_data = 'Необходимо согласиться на обработку персональных данных';
        }
        if (!agree_rules) {
          this.errors.agree_rules = 'Необходимо согласиться с правилами акции';
        }

        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.errors).length > 0) {
          return;
        }

        const utmParamsList = this.utmParams;

        // Отправка запроса на регистрацию с форматированным номером телефона
        await register(first_name, last_name, formattedPhoneNumber, email, JSON.stringify(utmParamsList), agree_to_advertising);

        // Закрываем окно регистрации
        this.closeRegisterFinishPopup();
        this.openRegisterPopupSuccess();

      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    switchToLogin() {
      this.addBodyClass();
      this.showResetPasswordSuccess = false;
      this.showLoginPopup = true;
      this.showRegisterPopup = false;
      this.responseError = '';
    }
  },
};
</script>

<style>
/* Ваши стили */
</style>
